<template>
  <div class="EtiquetteService">
    <div class="banner">
      <img
        src="@/assets/images/parkBanner.png"
        alt=""
      >
    </div>
    <div class="aside">
       <div  @click="routeClick" class="btn">立即预约</div>
       <!-- @click="routeTo('/AfterSalesService',{'companyId':companyId})" -->
    </div>
    <div class="container">
      <div v-html="listData.content"></div>
    </div>
  </div>
</template>

<script>
import api from '@/util/api'
export default {
  data(){
    return{
        companyId: this.$route.query.companyId || 0,
        listData:{},
    }
  },
  mounted(){
    this.search();
  },
  methods:{
    search(){
      api.homePageEtiquetteServiceDetail({id: this.companyId},res=>{
        this.listData = res.data;
      })
    },
    routeClick(){
      if (sessionStorage.getItem("token")) {
          this.$router.push({
          path:'/AfterSalesService',
          query: { companyId: this.companyId }, 
        });
        } else {
          this.$router.push({ path: "/login" });
        }
    }
  }
}
</script>

<style lang="less" scoped>
.EtiquetteService {
  .banner {
    height: 220px;
    
  }
  .btn{ 
    width: 149px;
    height: 33px;
    background: #299654;
    border-radius: 4px;
    line-height:33px;
    text-align: center;
    color:white;
    cursor: pointer;
  }
  .aside{
    display: flex;
    justify-content: flex-end;
    width:1120px;
    margin: 20px auto 0;
  }
  .yyItem{
    cursor: pointer;
    // position: absolute;
    // right:200px;
    // top:460px;
    width:80px;
    height:80px;
    background: linear-gradient(180deg, #299654 0%, rgba(41, 150, 84, 0.5) 100%);
    border-radius:50%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    color:white;
  }
  .container {
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 2px;
    padding: 24px;
    background: #ffffff;
    margin-top: 24px;
    margin-bottom: 24px;
    // height: 855px;
  }
}
</style>